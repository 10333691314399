import React, { useState, useEffect, useMemo } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { JobsData } from "../data/jobs-data";

interface Perk {
    title: string;
    icon: string;
}

const JobsPage = () => {
    const { t } = useTranslation();
    const jobs = useMemo(() => JobsData(t), []);

    const PERKS: Perk[] = [
        {
            title: t("Flexible working hours"),
            icon:
                "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z",
        },
        {
            title: t("Working with the newest technologies"),
            icon:
                "M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z",
        },
        {
            title: t("Powerful machines and 2 monitors (or more)"),
            icon:
                "M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z",
        },
        {
            title: t("Good coffee and tea"),
            icon:
                "M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z",
        },
        {
            title: t("Training and online learning courses like Pluralsight"),
            icon:
                "M528 0H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h192l-16 48h-72c-13.3 0-24 10.7-24 24s10.7 24 24 24h272c13.3 0 24-10.7 24-24s-10.7-24-24-24h-72l-16-48h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16 352H64V64h448v288z",
        },
        {
            title: t("Regular team outings"),
            icon:
                "M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z",
        },
        {
            title: t("Opportunity for technology certificates"),
            icon:
                "M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z",
        },
        {
            title: t("Home office on demand"),
            icon:
                "M272,288H208a16,16,0,0,1-16-16V208a16,16,0,0,1,16-16h64a16,16,0,0,1,16,16v37.12C299.11,232.24,315,224,332.8,224H469.74l6.65-7.53A16.51,16.51,0,0,0,480,207a16.31,16.31,0,0,0-4.75-10.61L416,144V48a16,16,0,0,0-16-16H368a16,16,0,0,0-16,16V87.3L263.5,8.92C258,4,247.45,0,240.05,0s-17.93,4-23.47,8.92L4.78,196.42A16.15,16.15,0,0,0,0,207a16.4,16.4,0,0,0,3.55,9.39L22.34,237.7A16.22,16.22,0,0,0,33,242.48,16.51,16.51,0,0,0,42.34,239L64,219.88V384a32,32,0,0,0,32,32H272ZM629.33,448H592V288c0-17.67-12.89-32-28.8-32H332.8c-15.91,0-28.8,14.33-28.8,32V448H266.67A10.67,10.67,0,0,0,256,458.67v10.66A42.82,42.82,0,0,0,298.6,512H597.4A42.82,42.82,0,0,0,640,469.33V458.67A10.67,10.67,0,0,0,629.33,448ZM544,448H352V304H544Z",
        },
    ];

    const data = useStaticQuery(graphql`
		query JobsPageQuery {
			allFile(filter: { relativePath: { in: ["banner/jobs.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

    const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

    return (
        <Layout>
            <SEO title="Jobs" />
            {/* Banner */}
            <BackgroundImage Tag="div" fluid={fluidBanner}>
                <div className="container text-white h-400px pt-4">
                    <div className="md:w-4/5 lg:w-2/3 xl:w-3/5 px-4 highlight">
                        <h1 className="pt-10 sm:pt-20 text-3xl sm:text-4xl leading-tight font-bold">
                            <span>
                                <Trans>Check out Our Open Positions</Trans>
                            </span>
                        </h1>
                        <p className="text-lg mt-4 lg:mt-8">
                            <Trans i18nKey="If you havent found anything fancy just send us your resume">
                                If you haven’t found anything fancy just send us your resume, portfolio, recommendation
                                letter or anything that you feel comfortable with. We always have a seat for a talent.
							</Trans>
                        </p>
                        <div className="mt-8">
                            <a
                                href={`mailto:cv@dgital.com?subject=apply for position @DGITAL`}
                                className="text-2xl hover:text-brand-green font-semibold uppercase"
                            >
                                Apply &#10230;
							</a>
                            <div>
                                <svg
                                    className="w-4 h-4 fill-current inline"
                                    viewBox="0 0 580 580"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z M202.992,332.528v124.517l58.738-67.927L202.992,332.528z" />
                                </svg>
                                <section className="text-sm font-semibold inline">
                                    <Trans i18nKey="cv@dgitaldotcom">cv@dgital.com</Trans>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <div className="container p-4">
                <h1 className="text-3xl mb-6 mt-3 font-bold">
                    <Trans>About Us</Trans>
                </h1>
                <div className="text-lg mb-6">
                    <Trans i18nKey="We are a fun and collaborative work crew">
                        We are a fun and collaborative work crew delivering state of the art online travel solutions
                        involving cutting-edge technologies. At DGITAL we honour our employees with excellent
                        remuneration, compensation package, family friendly work and workplace conditions. You can be
                        part of the success as a shareholder after 12 consecutive months in position by participating in
                        DGITAL employee stock purchase plan.
					</Trans>
                </div>
                <div className="text-lg mb-6">
                    <Trans i18nKey="Flexible hours and work from home occasionally are both possible">
                        Flexible hours and work from home occasionally are both possible and there are some smaller
                        perks, like complimentary refreshments and occasional social events. At DGITAL, we celebrate
                        diversity and inclusivity and are committed to creating and supporting equal employment
                        opportunities.
					</Trans>
                </div>
            </div>
            {/* Work at DGITAL */}
            <div className="bg-brand-gray4">
                <div className="container p-4">
                    <h1 className="uppercase font-semibold text-2xl mb-8">
                        <span className="text-brand-green mr-2">//</span>
                        <Trans>Work at Dgital</Trans>
                    </h1>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-4 gap-4">
                        {PERKS.map((perk) => (
                            <div key={perk.title}>
                                <div className="flex sm:mb-3 lg:mb-8">
                                    <svg
                                        className="h-12 fill-current w-1/4"
                                        viewBox="0 0 640 512"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d={perk.icon} />
                                    </svg>
                                    <div className="text-lg ml-2 w-3/4">{perk.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Open options */}
            <div className="container p-4">
                <div className="bg-white">
                    <div className="container mb-10">
                        <h1 className="mb-4 text-3xl font-bold">
                            <Trans>Open Positions</Trans>
                        </h1>
                        <div className="grid sm:grid-cols-3 sm:gap-4 lg:gap-12">
                            {jobs.map((job) => (
                                <AnchorLink
                                    to={"/jobs#" + job.id}
                                    className="w-full relative m-auto sm:h-32 lg:h-24 xl:h-20 shadow-xl sm:border sm:border-gray-100 py-2 mb-2"
                                    key={job.title}
                                >
                                    <div className="px-4 pr-4 md:pr-10 md:mt-2 lg:mt-0">
                                        <div className="text-lg inline border-b-2 border-brand-green">
                                            {job.category}
                                        </div>
                                        <div className="text-xl font-semibold mt-1 sm:mb-5">{job.title}</div>
                                        <div className="cursor-pointer text-brand-green hover:text-brand-gray2 text-right absolute inset-x-0 bottom-0 mb-4 lg:mb-2 mr-3 md:mr-5">
                                            &#10230;
										</div>
                                    </div>
                                </AnchorLink>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Jobs list */}
                {jobs
                    .filter((job) => job.enabled)
                    .map((job) => (
                        <div key={job.id} className="mb-8 bg-brand-gray4 p-4 pl-8">
                            <div id={job.id} className="mb-4">
                                <h1 className="text-2xl font-bold uppercase mt-2 mb-6">{job.title}</h1>
                                {job.descriptions.map((desc, i) => (
                                    <p key={i} className="text-lg font-bold mb-6">
                                        {desc}
                                    </p>
                                ))}
                            </div>
                            <div className="mb-4">
                                {job.lists.map((section) => (
                                    <div key={section.title}>
                                        <h2 className="text-xl font-bold mt-4 mb-2">{section.title}</h2>
                                        <ul className="text-base list-disc list-inside">
                                            {section.items.map((item, i) => (
                                                <li className="mb-1" key={i}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="text-right mr-5">
                                <a
                                    href={`mailto:cv@dgital.com?subject=apply for ${job.title} position @DGITAL`}
                                    className="text-2xl text-brand-green hover:text-brand-gray2 font-bold uppercase"
                                >
                                    Apply &#10230;
								</a>
                                <div>
                                    <svg
                                        className="w-4 h-4 fill-current inline"
                                        viewBox="0 0 580 580"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z M202.992,332.528v124.517l58.738-67.927L202.992,332.528z" />
                                    </svg>
                                    <section className="text-sm font-bold inline">
                                        <Trans i18nKey="cv@dgitaldotcom">cv@dgital.com</Trans>
                                    </section>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </Layout>
    );
};

export default JobsPage;
